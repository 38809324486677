<template>
  <b-container fluid>
    <b-row>
      <b-col sm="12">
        <iq-card>
          <template v-slot:headerTitle>
            <h4 class="card-title">Remixicon Icon</h4>
          </template>
          <template v-slot:body>
            <p>Use Class <code>&lt;i class="ri-4k-fill"&gt;&lt;/i&gt;</code></p>
            <b-row class="mt-3">
              <b-col lg="2" md="4" sm="6">
                <b-link class="iq-icons-list" href="#"><i class="ri-4k-fill"></i>4k Fill</b-link>
              </b-col>
              <b-col lg="2" md="4" sm="6">
                <b-link class="iq-icons-list" href="#"><i class="ri-4k-line"></i>4k line</b-link>
              </b-col>
              <b-col lg="2" md="4" sm="6">
                <b-link class="iq-icons-list" href="#"><i class="ri-account-box-fill"></i>Account Fill</b-link>
              </b-col>
              <b-col lg="2" md="4" sm="6">
                <b-link class="iq-icons-list" href="#"><i class="ri-account-pin-box-line"></i>account-pin-box</b-link>
              </b-col>
              <b-col lg="2" md="4" sm="6">
                <b-link class="iq-icons-list" href="#"><i class="ri-award-line"></i>award-line</b-link>
              </b-col>
              <b-col lg="2" md="4" sm="6">
                <b-link class="iq-icons-list" href="#"><i class="ri-award-fill"></i>award-fill</b-link>
              </b-col>
              <b-col lg="2" md="4" sm="6">
                <b-link class="iq-icons-list" href="#"><i class="ri-bookmark-line"></i>bookmark-line</b-link>
              </b-col>
              <b-col lg="2" md="4" sm="6">
                <b-link class="iq-icons-list" href="#"><i class="ri-bookmark-fill"></i>bookmark-fill</b-link>
              </b-col>
              <b-col lg="2" md="4" sm="6">
                <b-link class="iq-icons-list" href="#"><i class="ri-calculator-line"></i>calculator-line</b-link>
              </b-col>
              <b-col lg="2" md="4" sm="6">
                <b-link class="iq-icons-list" href="#"><i class="ri-calculator-fill"></i>calculator-fill</b-link>
              </b-col>
              <b-col lg="2" md="4" sm="6">
                <b-link class="iq-icons-list" href="#"><i class="ri-calendar-line"></i>calendar-line</b-link>
              </b-col>
              <b-col lg="2" md="4" sm="6">
                <b-link class="iq-icons-list" href="#"><i class="ri-calendar-fill"></i>calendar-fill</b-link>
              </b-col>
              <b-col lg="2" md="4" sm="6">
                <b-link class="iq-icons-list" href="#"><i class="ri-reply-line"></i>reply-line</b-link>
              </b-col>
              <b-col lg="2" md="4" sm="6">
                <b-link class="iq-icons-list" href="#"><i class="ri-reply-fill"></i>reply-fill</b-link>
              </b-col>
              <b-col lg="2" md="4" sm="6">
                <b-link class="iq-icons-list" href="#"><i class="ri-send-plane-line"></i>send-plane-line</b-link>
              </b-col>
              <b-col lg="2" md="4" sm="6">
                <b-link class="iq-icons-list" href="#"><i class="ri-send-plane-fill"></i>send-plane-fill</b-link>
              </b-col>
              <b-col lg="2" md="4" sm="6">
                <b-link class="iq-icons-list" href="#"><i class="ri-computer-line"></i>computer-line</b-link>
              </b-col>
              <b-col lg="2" md="4" sm="6">
                <b-link class="iq-icons-list" href="#"><i class="ri-computer-fill"></i>computer-fill</b-link>
              </b-col>
              <b-col lg="2" md="4" sm="6">
                <b-link class="iq-icons-list" href="#"><i class="ri-cellphone-line"></i>cellphone-line</b-link>
              </b-col>
              <b-col lg="2" md="4" sm="6">
                <b-link class="iq-icons-list" href="#"><i class="ri-cellphone-fill"></i>cellphone-fill</b-link>
              </b-col>
              <b-col lg="2" md="4" sm="6">
                <b-link class="iq-icons-list" href="#"><i class="ri-phone-line"></i>phone-line</b-link>
              </b-col>
              <b-col lg="2" md="4" sm="6">
                <b-link class="iq-icons-list" href="#"><i class="ri-phone-fill"></i>phone-fill</b-link>
              </b-col>
              <b-col lg="2" md="4" sm="6">
                <b-link class="iq-icons-list" href="#"><i class="ri-tablet-line"></i>tablet-line</b-link>
              </b-col>
              <b-col lg="2" md="4" sm="6">
                <b-link class="iq-icons-list" href="#"><i class="ri-tablet-fill"></i>tablet-fill</b-link>
              </b-col>
              <b-col lg="2" md="4" sm="6">
                <b-link class="iq-icons-list" href="#"><i class="ri-device-line"></i>device-line</b-link>
              </b-col>
              <b-col lg="2" md="4" sm="6">
                <b-link class="iq-icons-list" href="#"><i class="ri-device-fill"></i>device-fill</b-link>
              </b-col>
              <b-col lg="2" md="4" sm="6">
                <b-link class="iq-icons-list" href="#"><i class="ri-battery-line"></i>battery-line</b-link>
              </b-col>
              <b-col lg="2" md="4" sm="6">
                <b-link class="iq-icons-list" href="#"><i class="ri-battery-fill"></i>battery-fill</b-link>
              </b-col>
              <b-col lg="2" md="4" sm="6">
                <b-link class="iq-icons-list" href="#"><i class="ri-battery-low-line"></i>battery-low-line</b-link>
              </b-col>
              <b-col lg="2" md="4" sm="6">
                <b-link class="iq-icons-list" href="#"><i class="ri-battery-low-fill"></i>battery-low-fill</b-link>
              </b-col>
              <b-col lg="2" md="4" sm="6">
                <b-link class="iq-icons-list" href="#"><i class="ri-file-line"></i>file-line</b-link>
              </b-col>
              <b-col lg="2" md="4" sm="6">
                <b-link class="iq-icons-list" href="#"><i class="ri-file-fill"></i>file-fill</b-link>
              </b-col>
              <b-col lg="2" md="4" sm="6">
                <b-link class="iq-icons-list" href="#"><i class="ri-book-open-line"></i>book-open-line</b-link>
              </b-col>
              <b-col lg="2" md="4" sm="6">
                <b-link class="iq-icons-list" href="#"><i class="ri-book-open-fill"></i>book-open-fill</b-link>
              </b-col>
              <b-col lg="2" md="4" sm="6">
                <b-link class="iq-icons-list" href="#"><i class="ri-lightbulb-line"></i>lightbulb-line</b-link>
              </b-col>
              <b-col lg="2" md="4" sm="6">
                <b-link class="iq-icons-list" href="#"><i class="ri-lightbulb-fill"></i>lightbulb-fill</b-link>
              </b-col>
              <b-col lg="2" md="4" sm="6">
                <b-link class="iq-icons-list" href="#"><i class="ri-map-pin-line"></i>map-pin-line</b-link>
              </b-col>
              <b-col lg="2" md="4" sm="6">
                <b-link class="iq-icons-list" href="#"><i class="ri-map-pin-fill"></i>map-pin-fill</b-link>
              </b-col>
              <b-col lg="2" md="4" sm="6">
                <b-link class="iq-icons-list" href="#"><i class="ri-drop-line"></i>drop-line</b-link>
              </b-col>
              <b-col lg="2" md="4" sm="6">
                <b-link class="iq-icons-list" href="#"><i class="ri-drop-fill"></i>drop-fill</b-link>
              </b-col>
              <b-col lg="2" md="4" sm="6">
                <b-link class="iq-icons-list" href="#"><i class="ri-drop-fill"></i>drop-fill</b-link>
              </b-col>
              <b-col lg="2" md="4" sm="6">
                <b-link class="iq-icons-list" href="#"><i class="ri-drop-fill"></i>drop-fill</b-link>
              </b-col>
              <b-col sm="12" class="text-center mt-3">
                <a href="https://remixicon.com/" target="blank" class="btn btn-primary text-white">View All Icon</a>
              </b-col>
            </b-row>
          </template>
        </iq-card>
      </b-col>
    </b-row>
  </b-container>
</template>
<script>
import { core } from '../../config/pluginInit'
export default {
  name: 'IconRemixicon',
  mounted () {
    core.index()
  }
}
</script>
